import React from 'react';
import { ParallaxContext, useController } from 'react-scroll-parallax';
// styles
import 'src/scss/unbanned.scss';
// hooks
import unbannedMedia from 'src/hooks/unbannedMedia'
import unbannedMobileMedia from 'src/hooks/unbannedMobileMedia';
import useMediaQuery from 'src/hooks/useMediaQuery';
// components
import SEO from 'src/components/SEO';
import UnbannedContent from 'src/components/unbanned/UnbannedContent';
import MobileUnbannedContent from 'src/components/unbanned/UnbannedContentMobile';

// lib
import handlePageTransition from 'src/lib/handle-page-transition';

function UnbannedPage({ transitionStatus }) {
  let isDesktop = useMediaQuery('(min-width: 1024px)');
  const isServer = typeof window === 'undefined';
  const context = React.useContext(ParallaxContext)

  if (!isServer && context ) {
    const { parallaxController } = useController();
    handlePageTransition(parallaxController, transitionStatus);
  }

  const mobileImages = [];
  const images = [];
  const mobileMedia = unbannedMobileMedia();
  const media = unbannedMedia();

  mobileMedia.allCloudinaryMedia.edges.forEach(item => mobileImages.push(item.node.secure_url));
  media.allCloudinaryMedia.edges.forEach(item => images.push(item.node.secure_url));

  return (
    <div>
      <SEO title="Unbanned | Falkon Content" />
      <div>
        {isDesktop
          ? (<UnbannedContent images={images} />)
          : (<MobileUnbannedContent images={mobileImages} />)
        }
      </div>
    </div>
  );
}

export default React.memo(UnbannedPage);
