import React from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';
// utils
import { above, size } from 'src/utils';

export const ScrollSceneWrapper = styled.div`
  overflow: hidden;

  #pinContainer {
    overflow: hidden;
    width: 100vw;
    height: 100vh;

    .horizontal-wrapper {
      display: flex;
    }

    .panel {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      width: 100vw;
      height: 100vh;
    }

    .fluid-grid {
      display: grid;
      grid-auto-columns: 75px;
      grid-column-gap: 20px;
      height: 100vh;
    }

    .panel--last {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .last-image {
      width: 100vw;
      height: auto;

      ${above.large`
        width: ${size.large}px;
        height: ${size.large / (16 / 9)}px;
      `}

      ${above.xl`
        width: ${size.xl}px;
        height: ${size.xl / (16 / 9)}px;
      `}

      ${above.huge`
        width: ${size.huge}px;
        height: ${size.huge / (16 / 9)}px;
      `}
    }
  }
`;

export const Panel1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  width: 100vw;
  height: 100vh;

  img {
    width: 100vw;
    height: auto;

    ${above.large`
      width: ${size.large}px;
      height: ${size.large / (16 / 9)}px;
    `}

    ${above.xl`
      width: ${size.xl}px;
      height: ${size.xl / (16 / 9)}px;
    `}

    ${above.huge`
      width: ${size.huge}px;
      height: ${size.huge / (16 / 9)}px;
    `}
  }
`;

export const Panel1Quote = styled.div`
  position: absolute;
  width: ${100 * (1024 / 1600)}vw;

  ${above.huge`
    width: 1024px;
  `}

  blockquote {
    text-align: center;

    p {
      font-size: 6vw;
      line-height: 1;
      margin: 0 0 0.5em;
      font-family: 'Moret';
      text-transform: uppercase;

      ${above.huge`
        font-size: 96px;
      `}
    }

    cite {
      font-size: 1vw;
      line-height: 1;
      letter-spacing: 0.1em;
      margin: 0;
      font-weight: 900;
      text-transform: uppercase;
      font-style: normal;

      ${above.huge`
        font-size: 16px;
      `}
    }
  }
`;

export const Panel2 = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
`;

export const Panel2Wrapper = styled.div`
  position: relative;
  background: #a4272a;
  height: 68vh;
  width: 150vh;
`;

export const Panel2Img1 = styled.div`
  position: absolute;
  top: 10vh;
  left: 16.5vh;
  width: 48vh;

  img {
    max-width: 100%;
  }
`;

export const Panel2Blurb1 = styled.div`
  .main-copy {
    color: #000;
    font-size: ${100 * (12 / 1600)}vw;
    margin-left: 27vh;
    margin-top: 40vh;

    ${above.huge`
      font-size: 1.02vh;
    `}
  }
`;

export const Panel2Img2 = styled(Parallax)`
  position: absolute;
  top: 22.5vh;
  left: 61vh;
  width: 61vh;

  img {
    max-width: 100%;
  }
`;