import React from 'react';
import { Parallax } from 'react-scroll-parallax';
// components
import SectionTrigger from 'src/components/SectionTrigger';
import VideoPlayer from 'src/components/VideoPlayer';
import AutoPlayVideoPlayer from 'src/components/AutoPlayVideo';
import ParallaxImage from 'src/components/parallax/ParallaxImage';
import {
  Arrow,
  GooglePlayIcon,
  ItunesIcon,
  LionsgateIcon,
} from 'src/components/svgs';
import MobileHero from './UnbannedHeroMobile';
import MobileScrollGallery from './UnbannedScrollGalleryMobile';

function UnbannedContentMobile({ images }) {
  const scrollOffset = [40, -80];
  // const scrollOffset = [0,0];
  const [
    ubMobileImg01,
    ubMobileImg02,
    ubMobileImg03,
    ubMobileImg04,
    ubMobileImg05,
    ubMobileImg06,
    ubMobileImg07,
    ubMobileImg08,
    ubMobileImg09,
    ubMobileImg10,
    ubMobileImg11,
    ubMobileImg12,
    ubMobileImg13,
    ubMobileImg14,
    ubMobileImg15,
    ubMobileImg16,
    ubMobileImg17,
    ubMobileImg18,
    ubMobileImg19,
    ubMobileImg20,
  ] = images;
  const ubVid01 =
    'https://player.vimeo.com/external/433462421.hd.mp4?s=eeeafb4d3835691ae5b13dbb15a1606f5b3b8ef4&profile_id=175&oauth2_token_id=1365998561';
  const ubVid02 =
    'https://player.vimeo.com/external/433462441.sd.mp4?s=c6da0e7aa621cdb17f1aecd16071531c79986856&profile_id=139&oauth2_token_id=1365998561';

  return (
    <div className="ub-mobile">
      <SectionTrigger bgColor="#a4272a" textColor="#000">
        <MobileHero images={[ubMobileImg01]} />
      </SectionTrigger>
      <div className="ub-mobile__container">
        <img src={ubMobileImg02} className="ub-mobile__img ub-mobile__img--2" />
        <div className="ub-mobile__header">
          <p className="ub-mobile__subheading">
            A feature documentary film
          </p>
          <h2 className="ub-mobile__heading ub-mobile__heading--1">Unbanned</h2>
        </div>
        <p className="ub-mobile__copy ub-mobile__copy--1">The Legend of AJ1</p>
        <p className="ub-mobile__copy ub-mobile__copy--2">
          Born amid the most unlikely of circumstances and strapped to the feet of the Greatest Basketball Player of all time, Air Jordan 1 tapped such a cultural nerve deep inside a generation it broke through barriers of age, race, gender and time and ultimately transcended sport to become a global phenomenon.
        </p>
        <p className="ub-mobile__tagline ub-mobile__tagline--1">
          Independently developed, financed and produced the film is being distributed by Lionsgate.
        </p>
        <VideoPlayer
          className="ub-mobile__video ub-mobile__video--1"
          src={ubVid01}
          poster={ubMobileImg03}
        />
        <blockquote className="ub-mobile__blockquote ub-mobile__blockquote--1">
          &ldquo;An Air Jordan 1 billboard pops up in Los Angeles.&rdquo;
          <cite>Hypebeast</cite>
        </blockquote>
        <p className="ub-mobile__copy ub-mobile__copy--3">
          We launched with a mysterious billboard in the heart of LA’s Sneaker District, driving hardcore heads to debate WTF this shoe drop was across social?
        </p>
        <p className="ub-mobile__copy ub-mobile__copy--4">
        We further fueled the fire by “leaking” an internal design asset that set speculation ablaze on the possible forthcoming “sneaker release”.
        </p>
        <blockquote className="ub-mobile__blockquote ub-mobile__blockquote--2">
          &ldquo;Fans of His Airness will be excited...&rdquo;
          <cite>Hypebeast</cite>
        </blockquote>
        <blockquote className="ub-mobile__blockquote ub-mobile__blockquote--3">
          &ldquo;The AJ1 is more than a shoe. It's a symbol of a much greater meaning across genders, race, geography and time.&rdquo;
          <cite>Dexton Deboree</cite>
        </blockquote>
        <p className="ub-mobile__copy ub-mobile__copy--5">
          We dropped our official trailer on TNT during All-Star Weekend’s Dunk Contest, paying off the hype, quashing the debate & revealing the sneaker was in fact a movie.
        </p>
        <Parallax className="ub-mobile__img ub-mobile__img--3" y={scrollOffset}>
          <ParallaxImage src={ubMobileImg04}  />
        </Parallax>
        <img src={ubMobileImg05} className="global-box-shadow ub-mobile__img ub-mobile__img--4" />
        <Parallax className="ub-mobile__img ub-mobile__img--5" y={scrollOffset}>
          <ParallaxImage className="global-box-shadow" src={ubMobileImg06}  />
        </Parallax>
        {/* <img src={ubMobileImg06} className="global-box-shadow ub-mobile__img ub-mobile__img--5" /> */}
        <AutoPlayVideoPlayer
          className="ub-mobile__video ub-mobile__video--2"
          src={ubVid02}
          poster={ubMobileImg07}
        />
        <img src={ubMobileImg08} className="ub-mobile__img ub-mobile__img--6" />
        <Parallax className="ub-mobile__img ub-mobile__img--7" y={scrollOffset}>
          <ParallaxImage src={ubMobileImg09}  />
        </Parallax>
        {/* <img src={ubMobileImg09} className="ub-mobile__img ub-mobile__img--7" /> */}
        <img src={ubMobileImg10} className="ub-mobile__img ub-mobile__img--8" />
        <Parallax className="ub-mobile__img ub-mobile__img--9" y={scrollOffset}>
          <ParallaxImage src={ubMobileImg11}  />
        </Parallax>
        {/* <img src={ubMobileImg11} className="ub-mobile__img ub-mobile__img--9" /> */}
        <img src={ubMobileImg12} className="ub-mobile__img ub-mobile__img--10" />
        <MobileScrollGallery images={[ubMobileImg13, ubMobileImg14, ubMobileImg15, ubMobileImg16]} />
        <Arrow modifier="ub-mobile__scroll-gallery-arrow" />
        <img src={ubMobileImg17} className="ub-mobile__img ub-mobile__img--15" />
        <p className="ub-mobile__copy ub-mobile__copy--7">
          We hosted a series of exclusive, private screenings around the world for the most loyal sneaker fans, in the ultimate limited edition experience to see the film before the public.
        </p>
        <img src={ubMobileImg18} className="ub-mobile__img ub-mobile__img--16" />
        <p className="ub-mobile__copy ub-mobile__copy--8">
          The Smithsonian National Museum of African American History and Culture hosted us for a special screening, followed by an important panel discussion, featuring legendary basketball great, Carmelo Anthony, Jordan Brand Chairman, Larry Miller, Jordan VP, Howard White, The Museum’s Sports Curator Damion Thomas and ESPN’s Michael Smith & Jamele Hill as our esteemed Moderators.
        </p>
        <p className="ub-mobile__tagline ub-mobile__tagline--2">
          The film is currently available on digital platforms around the globe.
        </p>
        <img src={ubMobileImg19} className="ub-mobile__img ub-mobile__img--17" />
        <p className="ub-mobile__cite ub-mobile__cite--1">
          Cast & Crew
        </p>
        <Parallax className="ub-mobile__heading ub-mobile__heading--2" y={scrollOffset}>
          <h2 className="now-playing">Now Playing</h2>
        </Parallax>
        <img src={ubMobileImg20} className="ub-mobile__img ub-mobile__img--18" />
        <LionsgateIcon modifier="ub-mobile__img ub-mobile__img--19" />
        <ItunesIcon modifier="ub-mobile__img ub-mobile__img--20" />
        <GooglePlayIcon modifier="ub-mobile__img ub-mobile__img--21" />
      </div>
      <SectionTrigger
        bgColor="#f0f0f0"
        className="ub-mobile__section ub-mobile__section--1" textColor="#000"
      />
      <SectionTrigger
        bgColor="#1e1e1e"
        className="ub-mobile__section ub-mobile__section--2" textColor="#fff"
      />
      <SectionTrigger
        bgColor="#1e1e1e"
        className="ub-mobile__section ub-mobile__section--3" textColor="#fff"
      />
      <SectionTrigger
        bgColor="#f0f0f0"
        className="ub-mobile__section ub-mobile__section--4" textColor="#000"
      />
    </div>
  )
}

export default UnbannedContentMobile;