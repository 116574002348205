import React from 'react';
import { array } from 'prop-types';

function ImageGallery({ images }) {
  const [imageArr] = images;

  return (
    <section className="styled-scrollbar image-gallery">
      <div className="image-gallery__container">
        <div className="image-gallery__wrapper">
          {imageArr.map((img, i) => (
            <img className="image-wrapper" src={img} key={i} alt="no tag" />
          ))}
        </div>
      </div>
    </section>
  );
}

ImageGallery.propTypes = {
  images: array.isRequired,
};

export default ImageGallery;
