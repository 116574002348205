import React from 'react';

function PlaygroundScrollGalleryMobile({ images }) {
  const [
    ubMobileImg13,
    ubMobileImg14,
    ubMobileImg15,
    ubMobileImg16,
  ] = images;
  return (
    <div className="ub-mobile__scroll-gallery">
      <img src={ubMobileImg13} className="ub-mobile__img ub-mobile__img--11" />
      <p className="ub-mobile__copy ub-mobile__copy--6">
        We premiered at the Tribeca Film Festival at the historic Beacon Theater before 2,700 fans, followed by a surprise hip-hop show featuring Fat Joe, Kid Ink and Gizzle.
      </p>
      <img src={ubMobileImg14} className="ub-mobile__img ub-mobile__img--12" />
      <img src={ubMobileImg15} className="ub-mobile__img ub-mobile__img--13" />
      <img src={ubMobileImg16} className="ub-mobile__img ub-mobile__img--14" />
    </div>
  )
}

export default PlaygroundScrollGalleryMobile;