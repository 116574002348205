import React from 'react';

function UnbannedHeroMobile({ images }) {
  const [ubMobileImg01] = images;
  return (
    <div className="ub-mobile__hero">
      <img className="ub-mobile__img ub-mobile__img--1" src={ubMobileImg01} />
    </div>
  )
}

export default UnbannedHeroMobile;