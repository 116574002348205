import React, { useState, useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
// components
import * as Styled from './UnbannedScrollSceneStyled';

// eslint-disable-next-line react/prop-types
const UnbannedScrollGallery = () => {
  const data = useStaticQuery(graphql`
    query UnbannedScrollScene {
      allCloudinaryMedia(
        filter: { tags: { eq: "unbanned-scroll-gallery" } }
        sort: { fields: public_id, order: ASC }
      ) {
        edges {
          node {
            secure_url
          }
        }
      }
    }
  `);
  const cloudMedia = data.allCloudinaryMedia.edges;
  const images = [];
  cloudMedia.map(image => images.push(image.node.secure_url));

  const [
    titleSequence11,
    lenaWaithe12,
    chuckDSplit13,
    titleSequenceWide15,
  ] = images;

  const [width, setWidth] = useState(0);
  const [minusWidth, setMinusWidth] = useState(0);

  const ref = useCallback(node => {
    if (node !== null) {
      const w = node.scrollWidth;
      setWidth(width + w);
    }
  }, []);

  const minusRef = useCallback(node => {
    if (node !== null) {
      const w = node.scrollWidth;
      setMinusWidth(w);
    }
  }, []);

  return (
    <Styled.ScrollSceneWrapper>
      <Controller>
        <Scene triggerHook="onLeave" duration={width} pin>
          <Timeline wrapper={<div id="pinContainer" ref={ref} />}>
            <Tween to={{ x: `-${width - minusWidth}` }}>
              <section className="horizontal-wrapper unbanned-scroll">
                <PanelOne images={[titleSequence11]} />
                <PanelTwo images={[lenaWaithe12, chuckDSplit13]} />
                <PanelThree
                  minusRef={minusRef}
                  images={[titleSequenceWide15]}
                />
              </section>
            </Tween>
          </Timeline>
        </Scene>
      </Controller>
    </Styled.ScrollSceneWrapper>
  );
};

// eslint-disable-next-line react/prop-types
function PanelOne({ images }) {
  const [img01] = images;
  return (
    <Styled.Panel1>
      <Styled.Panel1Quote>
        <blockquote>
          <p>
            &ldquo;The AJI is more than a shoe. It’s a symbol of a much greater
            meaning across genders, race, geography and time.&rdquo;
          </p>
          <cite>Dexton Deboree</cite>
        </blockquote>
      </Styled.Panel1Quote>
      <img src={img01} alt="no tag" />
    </Styled.Panel1>
  );
}

// eslint-disable-next-line react/prop-types
function PanelTwo({ images }) {
  const [img01, img02] = images;
  return (
    <Styled.Panel2>
      <Styled.Panel2Wrapper>
        <Styled.Panel2Img1>
          <img src={img01} alt="no tag" />
        </Styled.Panel2Img1>
        <Styled.Panel2Blurb1>
          <p className="main-copy">
            We premiered at the Tribeca
            <br />
            Film Festival at the historic
            <br />
            Beacon Theater before 2,700
            <br />
            fans, followed by a surprise
            <br />
            hip-hop show featuring Fat
            <br />
            Joe, Kid Ink and Gizzle.
          </p>
        </Styled.Panel2Blurb1>
        <Styled.Panel2Img2>
          <img src={img02} alt="no tag" />
        </Styled.Panel2Img2>
      </Styled.Panel2Wrapper>
    </Styled.Panel2>
  );
}

// eslint-disable-next-line react/prop-types
function PanelThree({ minusRef, images }) {
  const [img01] = images;
  return (
    <div className="panel panel--last" ref={minusRef}>
      <img className="last-image" src={img01} alt="no tag" />
    </div>
  );
}

export default React.memo(UnbannedScrollGallery);
