import React from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';
import SectionTrigger from '../SectionTrigger';
// utils
import { above } from '../../utils';

export const Section1 = styled(SectionTrigger).attrs(() => ({
  bgColor: '#f0f0f0',
  textColor: '#000',
}))`
  width: 100vw;
`;

export const Section1Blurb = styled(Parallax)`
  grid-column: 1 / 6;
  grid-row: 1;
  position: relative;
  z-index: 10;

  .supTitle {
    font-size: ${(16 / 1600) * 100}vw;
    line-height: 1.2;
    font-weight: 900;
    letter-spacing: 0.1em;
    margin: 0;
    max-width: 100%;
    text-transform: uppercase;

    ${above.huge`
      font-size: 16px;
    `}
  }

  h1 {
    font-size: ${(60 / 1600) * 100}vw;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 0.025em;
    margin: 0.5em 0;

    ${above.huge`
      font-size: 60px;
    `}
  }

  p {
    max-width: 80%;
  }

  .subTitle {
    margin-bottom: 3em;
  }
`;

export const Section1Img = styled(Parallax)`
  grid-column: 4 / 13;
  grid-row: 1;
`;

export const Section3 = styled(SectionTrigger).attrs(() => ({
  bgColor: '#f0f0f0',
  textColor: '#000',
}))`
  width: 100vw;
`;

export const Section3Img1 = styled(Parallax)`
  grid-area: 1 / 7 / auto / 10;
  position: relative;
  z-index: 10;
`;

export const Section3Quote = styled(Parallax)`
  grid-area: 1 / 2 / auto / 12;
  z-index: 9;
  margin: 10% 0;

  blockquote {
    text-align: center;

    p {
      font-size: ${(100 / 1600) * 100}vw;
      line-height: 1;
      margin: 0 0 0.5em;
      font-family: 'Moret';
      text-transform: uppercase;
      ${above.huge`
        font-size: 100px;
      `}
    }

    cite {
      font-size: ${(16 / 1600) * 100}vw;
      line-height: 1;
      letter-spacing: 0.1em;
      margin: 0;
      font-weight: 900;
      text-transform: uppercase;
      font-style: normal;

      ${above.huge`
        font-size: 16px;
      `}
    }
  }
`;

export const Section3Img2 = styled(Parallax)`
  grid-area: 2 / 1 / auto / 8;
  position: relative;
  z-index: 1;

  ${above.huge`
    margin: 0;
  `}

  img {
    box-shadow: ${(65 / 1600) * 100}vw ${(65 / 1600) * 100}vw 3px
      rgba(0, 0, 0, 0.34);

    ${above.huge`
      box-shadow: 65px 65px 3px rgba(0, 0, 0, 0.34);
    `}
  }
`;

export const Section3Img3 = styled(Parallax)`
  grid-area: 3 / 7 / auto / 13;
  position: relative;
  z-index: 5;

  ${above.huge`
    margin: 0;
  `}

  img {
    box-shadow: ${(65 / 1600) * 100}vw ${(65 / 1600) * 100}vw 3px
      rgba(0, 0, 0, 0.34);

    ${above.huge`
      box-shadow: 65px 65px 3px rgba(0, 0, 0, 0.34);
    `}
  }
`;

export const Section3Blurb1 = styled(Parallax)`
  grid-area: 2 / 9 / auto / 13;
  z-index: 10;
  align-self: self-start;
`;

export const Section3Blurb2 = styled(Parallax)`
  grid-area: 3 / 1 / auto / 5;
  z-index: 10;
  align-self: self-end;
`;

export const Section4 = styled(SectionTrigger).attrs(() => ({
  bgColor: '#1e1e1e',
  textColor: '#fff',
}))`
  width: 100vw;

  blockquote {
    text-align: center;

    p {
      font-size: ${(100 / 1600) * 100}vw;
      line-height: 1;
      margin: 0 0 0.5em;
      font-family: 'Moret';
      text-transform: uppercase;

      ${above.huge`
        font-size: 100px;
      `}
    }

    cite {
      font-size: ${(16 / 1600) * 100}vw;
      line-height: 1;
      letter-spacing: 0.1em;
      margin: 0;
      font-weight: 900;
      text-transform: uppercase;
      font-style: normal;

      ${above.huge`
        font-size: 16px;
      `}
    }
  }

  .billboard-video {
    width: 100vw;
    grid-row: 2 / 2;
    justify-self: center;
    grid-column: 1 / 13;
    margin-bottom: 10%;
  }
`;

export const Section4Blurb1 = styled(Parallax)`
  grid-area: 3 / 9 / auto / 13;
  z-index: 10;
`;

export const Section5 = styled(SectionTrigger).attrs(() => ({
  bgColor: '#1e1e1e',
  textColor: '#fff',
}))`
  width: 100vw;
`;

export const Section5Blurb1 = styled(Parallax)`
  grid-area: 2 / 9 / auto / 13;
  z-index: 11;

  p {
    font-size: 1.25vw;
    line-height: 1.5;
    margin: 0;
    font-weight: 700;

    ${above.huge`
      font-size: 20px;
    `}
  }
`;

export const Section6 = styled(SectionTrigger)`
  width: 100vw;

  .imdb-link {
    grid-area: 2 / 1 / auto / 13;

    p {
      text-align: center;
      text-transform: uppercase;
      font-size: ${(30 / 1600) * 100}vw;
      margin: 30% 0 30%;
      font-weight: 900;
      letter-spacing: 0.1em;

      ${above.huge`
        font-size: 30px;
      `}

      a {
        text-decoration: none;
        display: inline-block;
        border-bottom: solid 0.15em;
        padding: 0.15em 0;
      }
    }
  }

  .watch-link {
    grid-area: 5 / 1 / auto / 13;
    z-index: 11;

    p {
      text-align: center;
      text-transform: uppercase;
      font-size: ${(30 / 1600) * 100}vw;
      font-weight: 900;
      letter-spacing: 0.1em;
      margin: 0;
      padding-bottom: 10%;

      ${above.huge`
        font-size: 30px;
      `}

      a {
        color: #000;
        text-decoration: none;
        display: inline-block;
        border-bottom: solid 0.15em #000;
        padding: 0.15em 0;
      }
    }
  }
`;

export const ComingSoon = styled(SectionTrigger)`
  margin: 5% 0;

  .grid {
    &:first-of-type {
      margin-bottom: 2vw;
    }
  }

  .lionsgate-logo {
    grid-area: 1 / 5 / auto / 9;
  }

  .itunes-logo {
    grid-area: 4 / 2 / auto / 4;
    align-self: center;
  }

  .google-play-logo {
    grid-area: 4 / 6 / auto / 8;
    align-self: center;
  }

  .tidal-logo {
    grid-area: 4 / 10 / auto / 12;
    align-self: center;
  }
`;

export const Section6Blurb1 = styled(Parallax)`
  grid-area: 1 / 9 / auto / 13;
  z-index: 11;
  align-self: self-end;
`;

export const Section6Blurb2 = styled(Parallax)`
  grid-area: 3 / 1 / auto / 5;
  z-index: 11;
`;

export const Section6Blurb3 = styled(Parallax)`
  grid-area: 3 / 6 / auto / 13;
  z-index: 11;

  p {
    font-size: ${(60 / 1600) * 100}vw;
    line-height: 1;
    margin: 0;
    font-weight: 900;
    text-align: right;
    text-transform: uppercase;

    &::before,
    &::after {
      display: block;
      content: 'Now Playing';
    }

    ${above.huge`
      font-size: 60px;
    `}
  }
`;
