import React from 'react';
import { Parallax } from 'react-scroll-parallax';
// components
import CaseStudyNav from 'src/components/CaseStudyFooterNav';
import SectionTrigger from 'src/components/SectionTrigger';
import ZoomVideo from 'src/components/ZoomVideo';
import {
  LionsgateIcon,
  TidalIcon,
  ItunesIcon,
  GooglePlayIcon,
} from 'src/components/svgs';
import ParallaxImage from 'src/components/parallax/ParallaxImage';
import AutoPlayVideo from 'src/components/AutoPlayVideo';
import * as Styled from 'src/components/unbanned/UnbannedStyled';
import ScrollScene from 'src/components/unbanned/UnbannedScrollScene';
import UnbannedSlide from 'src/components/unbanned/UnbannedSlideShow';


function UnbannedContent({ images }) {
  const [
    title1,
    banAd2,
    hangingJumpman,
    hypebeastBilboard4,
    hypebeastTweet5,
    spike7,
    aa8,
    daoyi9,
    mbj10,
    simthsoian16,
    alyamusic16,
    hero14,
    hulu,
    braids18,
  ] = images;
  const nightTimelapseVid =
    'https://player.vimeo.com/external/433462441.sd.mp4?s=c6da0e7aa621cdb17f1aecd16071531c79986856&profile_id=139&oauth2_token_id=1365998561';
  const unbannedTrailerVid =
    'https://player.vimeo.com/external/433462421.hd.mp4?s=eeeafb4d3835691ae5b13dbb15a1606f5b3b8ef4&profile_id=175&oauth2_token_id=1365998561';

  return (
    <div className="unbanned">
      <SectionTrigger className="hero" bgColor="#A4272A" textColor="#000">
        <img src={title1} alt="unbanned title" />
      </SectionTrigger>
      <Styled.Section1 bgColor="#1e1e1e" textColor="#000">
        <div className="grid__container">
          <div className="grid">
            <Styled.Section1Blurb y={[50, -50]}>
              <p className="supTitle">Lionsgate</p>
              <h1>Unbanned: The Legend of AJ1</h1>
              <p className="subTitle">A Feature Documentary Film</p>
              <p>
                Unbanned is the powerful story of the Shoe that changed the
                World.
              </p>
              <p>
                Born amid the most unlikely of circumstances and strapped to
                the feet of the Greatest Basketball Player of all time, Air
                Jordan 1 tapped such a cultural nerve deep inside a generation
                it broke through barriers of age, race, gender and time and
                ultimately transcended sport to become a global phenomenon.
              </p>
              <p>
                Independently developed, financed and produced the film is
                being distributed by Lionsgate.
              </p>
            </Styled.Section1Blurb>
            {/* <Styled.Section1Img y={[0, -40]}> */}
            <Styled.Section1Img>
              <ParallaxImage src={banAd2} alt="unbanned ad" />
            </Styled.Section1Img>
          </div>
        </div>
      </Styled.Section1>
      <ZoomVideo autoPlayVideo={true} src={unbannedTrailerVid} />
      <Styled.Section3>
        <div className="grid__container">
          <div className="grid">
            <Styled.Section3Img1 y={[-100, 0]}>
              <ParallaxImage src={hangingJumpman} alt="jumpman chain" />
            </Styled.Section3Img1>
            <Styled.Section3Quote y={[50, 0]}>
              <blockquote>
                <p>
                  &ldquo;An Air Jordan 1 Billboard Pops Up In Los
                  Angeles&rdquo;
                </p>
                <cite>Hypebeast</cite>
              </blockquote>
            </Styled.Section3Quote>
            <Styled.Section3Img2 y={[0, 20]}>
              <ParallaxImage
                src={hypebeastBilboard4}
                className="global-box-shadow"
                alt="hypebeast bilboard"
              />
            </Styled.Section3Img2>
            <Styled.Section3Blurb1 y={[100, 50]}>
              <p>
                We launched with a mysterious billboard in the heart of LA’s
                Sneaker District, driving hardcore heads to debate WTF this
                shoe drop was across social?
              </p>
            </Styled.Section3Blurb1>
            <Styled.Section3Img3 y={[0, -100]}>
              <ParallaxImage
                src={hypebeastTweet5}
                className="global-box-shadow"
                alt="hypebeast tweet"
              />
            </Styled.Section3Img3>
            <Styled.Section3Blurb2 y={[0, -100]}>
              <p>
                We further fueled the fire by “leaking” an internal design
                asset that set speculation ablaze on the possible forthcoming
                “sneaker release”.
              </p>
            </Styled.Section3Blurb2>
          </div>
        </div>
      </Styled.Section3>
      <Styled.Section4>
        <div className="grid__container">
          <div className="grid">
            <Parallax
              y={[20, -20]}
              styleOuter={{
                gridArea: '1 / 2 / auto / 12',
                marginBottom: '20%',
              }}
            >
              <blockquote>
                <p>&ldquo;Fans of his airness will be excited...&rdquo;</p>
                <cite>Hypebeast</cite>
              </blockquote>
            </Parallax>
            <AutoPlayVideo className="billboard-video" src={nightTimelapseVid} />
            <Styled.Section4Blurb1 y={[40, -80]}>
              <p>
                We dropped our official trailer on TNT during All-Star
                Weekend’s Dunk Contest, paying off the hype, squashing the
                debate & revealing the sneaker was in fact a movie.
              </p>
            </Styled.Section4Blurb1>
            <Parallax
              y={[0, 50]}
              styleOuter={{
                gridArea: '3 / 1 / auto / 10',
                zIndex: 1,
              }}
            >
              <ParallaxImage src={spike7} alt="spike lee" />
            </Parallax>
            <Parallax
              y={[10, 0]}
              styleOuter={{
                gridArea: '4 / 5 / auto / 13',
                zIndex: 7,
              }}
            >
              <ParallaxImage src={aa8} alt="anthony anderson" />
            </Parallax>
            <Parallax
              y={[-30, 0]}
              styleOuter={{
                gridArea: '5 / 1 / auto / 8',
                zIndex: 3,
              }}
            >
              <ParallaxImage src={daoyi9} alt="no alt" />
            </Parallax>
            <Parallax
              y={[40, 0]}
              styleOuter={{
                gridArea: '6 / 1 / auto / 13',
                zIndex: 3,
                margin: '-20% 0 0',
              }}
            >
              <ParallaxImage src={mbj10} alt="no alt" />
            </Parallax>
          </div>
        </div>
      </Styled.Section4>
      <ScrollScene />
      <Styled.Section5>
        <div className="grid__container">
          <div className="grid">
            <Parallax
              y={[0, -10]}
              styleOuter={{
                gridArea: '1 / 2 / auto / 12',
                zIndex: 10,
              }}
            >
              <ParallaxImage src={alyamusic16} alt="no alt" />
            </Parallax>
            <Styled.Section5Blurb1 y={[0, -100]}>
              <p>
                We hosted a series of exclusive, private screenings around the
                world for the most loyal sneaker fans, in the ultimate limited
                edition experience to see the film before the public.
              </p>
            </Styled.Section5Blurb1>
          </div>
        </div>
      </Styled.Section5>
      <div style={{ position: 'relative' }}>
        <UnbannedSlide />
        <div className="container container--small">
          <span className="scroll-arrow scroll-arrow--right" />
        </div>
      </div>
      <Styled.Section6 bgColor="#1e1e1e" textColor="#fff">
        <div className="grid__container">
          <div className="grid">
            <Parallax
              y={[0, 30]}
              styleOuter={{
                gridArea: '1 / 1 / auto / 10',
                zIndex: 1,
              }}
            >
              <ParallaxImage src={simthsoian16} alt="smithsonian" />
            </Parallax>
            <Styled.Section6Blurb1 y={[20, -20]}>
              <p>
                The Smithsonian National Museum of African American History
                and Culture hosted us for a special screening, followed by an
                important panel discussion, featuring legendary basketball
                great, Carmelo Anthony, Jordan Brand Chairman, Larry Miller,
                Jordan VP, Howard White, The Museum’s Sports Curator Damion
                Thomas and ESPN's Michael Smith & Jamele Hill as our esteemed
                Moderators.
              </p>
            </Styled.Section6Blurb1>
            <div className="imdb-link">
              <p>
                <a
                  href="https://www.imdb.com/title/tt8036174/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Cast &amp; Crew
                </a>
              </p>
            </div>
            <Styled.Section6Blurb2 y={[0, -200]}>
              <p>
                The film is currently available in the US. Coming soon to
                platforms and networks around the globe this year.
              </p>
            </Styled.Section6Blurb2>
            <Styled.Section6Blurb3 y={[-30, 50]}>
              <p>Now Playing</p>
            </Styled.Section6Blurb3>
            <div
              style={{
                gridArea: '4 / 1 / auto / 13',
                zIndex: 1,
                width: '100vw',
                justifySelf: 'center',
              }}
            >
              <img src={hulu} alt="hulu branding" />
            </div>
            <Parallax y={[-100, -200]} className="watch-link">
              <p>
                <a
                  href="https://www.hulu.com/movie/unbanned-the-legend-of-aj1-4b83093f-e354-428d-b5c6-f1a8311f1ed6"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Watch Now
                </a>
              </p>
            </Parallax>
          </div>
        </div>
      </Styled.Section6>
      <Styled.ComingSoon bgColor="#f0f0f0" textColor="#000">
        <div className="unbanned__cs">
          <div className="unbanned__cs-logo unbanned__cs-logo--lionsgate">
            <LionsgateIcon />
          </div>
          <div className="unbanned__cs-logo unbanned__cs-logo--itunes">
            <ItunesIcon />
          </div>
          <div className="unbanned__cs-logo unbanned__cs-logo--googleplay">
            <GooglePlayIcon />
          </div>
          <div className="unbanned__cs-logo unbanned__cs-logo--tidal">
            <TidalIcon />
          </div>
        </div>
      </Styled.ComingSoon>
      <CaseStudyNav
        nextTitle="&ldquo;The Ones&rdquo;"
        nextImg={braids18}
        nextLink="the-ones"
        prevTitle="The Playground"
        prevImg={hero14}
        prevLink="the-playground"
      />
    </div>
  )
}

export default UnbannedContent;